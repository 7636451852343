
import React, { useEffect, useRef, useState } from 'react'
import {
    Row,
    Col,
    Form,
    Input,
    DatePicker,
    Select,
    message,
    Checkbox,
    Switch,
    Upload,
    Space,
    TimePicker,
    Button
} from "antd";
import styled from "@emotion/styled";
import moment from "moment";
import {
    NON_EMPTY_REGEX,
    EMAIL_REGEX,
    countryList,
    Greetings,
    AwardList,
} from "common/Constants";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { isEmpty } from 'lodash';
import { CALL_API } from 'common/API';


const StyledButton = styled(Button)`
  margin: 10px;
  background: #26a4ff;
  border-radius: 5px;
  color: #ffffff;
  width: 100px;
`;

const StyleRow1 = styled(Row)`

  .ant-form-item-label {
    text-align: left;
  }
  .ant-picker{
    width: 100% !important;
  }
  .ant-upload.ant-upload-select {
    width: 100% !important;
    color: #397ff4 !important;
    .ant-btn > span {
      display: inline-block;
      color: #397ff4;
    }
    button,
    html [type="button"],
    [type="reset"],
    [type="submit"] {
      background: #f2f2f2;
    }
    .ant-upload-list-picture .ant-upload-list-item,
    .ant-upload-list-picture-card .ant-upload-list-item {
      height: 40px;
    }
    .ant-upload-list-text .ant-upload-list-item-name,
    .ant-upload-list-picture .ant-upload-list-item-name {
      color: #397ff4;
    }
  }
`;


const StyleRow = styled(Row)`
  padding: 10px;
  box-shadow: 0px 3px 6px #00000015;
  .ant-form-item-label {
    text-align: left;
  }
  .ant-picker{
    width: 100% !important;
  }
  .ant-upload.ant-upload-select {
    width: 100% !important;
    color: #397ff4 !important;
    .ant-btn > span {
      display: inline-block;
      color: #397ff4;
    }
    button,
    html [type="button"],
    [type="reset"],
    [type="submit"] {
      background: #f2f2f2;
    }
    .ant-upload-list-picture .ant-upload-list-item,
    .ant-upload-list-picture-card .ant-upload-list-item {
      height: 40px;
    }
    .ant-upload-list-text .ant-upload-list-item-name,
    .ant-upload-list-picture .ant-upload-list-item-name {
      color: #397ff4;
    }
  }
`;

function generateUniqueId() {
    const usedIds = [];
    let uniqueId = "";
    while (uniqueId.length !== 6) {
      const randomNumber = Math.floor(Math.random() * 900000) + 100000;
      if (!usedIds.includes(randomNumber.toString())) {
        usedIds.push(randomNumber.toString());
        uniqueId += randomNumber.toString();
      }
    }
    return uniqueId;
  }

function BankInformation({ goBack, setFormValues, handleSubmit, formValues, form, showLoader, setSignUniqueId }) {
    const containerRef = useRef(null);
    const onFinish = (values) => {
        const currentFormValues = form.getFieldValue();
        setFormValues((prevValues) => ({
            ...prevValues,
            ...currentFormValues,
        }));
        handleSubmit({
            ...formValues,
            ...currentFormValues,
        })
        // Handle API calls or state updates here
    };

    useEffect(()=> {
        if(!isEmpty(formValues)){
          form.setFieldsValue(formValues);
        }
      }, [formValues])

      
  const uploadFile = async (value) => {
    const { file } = value;
    try {
      if (!isEmpty(file)) {
        const { user_id } = formValues;
        setSignUniqueId(file.response.uniqueId)
        await CALL_API(`signature-upload/WASZS4S`, "post", {
          emailid: user_id?.toLowerCase(),
          name: file.name,
          type: file.type,
          uniqueId: file.response.uniqueId || file.uid,
          content: file.response.base64Data || file.base64Data,
        }
    );
      }
    } catch (error) {
      console.error("[handlesubmit] error --->", error);
    }
  };

    return (
        <div>
            <>
                <b className="mt-2 mx-1">Banking Information</b>
                <StyleRow className="m-1 mt-2 mb-3" gutter={20}>
                    <Col xs={24} sm={24} md={12}>
                        <Form.Item
                            name="accountname"
                            label="Account Name"
                            rules={[
                                {
                                    required: false,
                                    message: "Account Name is required",
                                },
                            ]}
                        >
                            <Input
                                type="text"
                                placeholder="Account Name"
                                // maxLength={500}
                                onKeyDown={(e) => {
                                    const allowedChars = /^[a-zA-Z\s]+$/;
                                    if (!allowedChars.test(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                        <Form.Item
                            name="accountno"
                            label="Account Number"
                            rules={[
                                {
                                    required: false,
                                    message: "Account Number is required",
                                },
                            ]}
                        >
                            <Input
                                type="text"
                                placeholder="Account Number"
                                maxLength={25}
                            // onKeyDown={(e) => {
                            //   const allowedChars = /^[a-zA-Z\s]+$/;
                            //   if (!allowedChars.test(e.key)) {
                            //     e.preventDefault();
                            //   }
                            // }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                        <Form.Item
                            name="bankname"
                            label="Bank Name"
                            rules={[
                                {
                                    required: false,
                                    message: "Bank Name is required",
                                },
                            ]}
                        >
                            <Input
                                type="text"
                                placeholder="Bank Name"
                                // maxLength={500}
                                onKeyDown={(e) => {
                                    const allowedChars = /^[a-zA-Z\s]+$/;
                                    if (!allowedChars.test(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                        <Form.Item
                            name="bankbranch"
                            label="Branch"
                            rules={[
                                {
                                    required: false,
                                    message: "Branch is required",
                                },
                            ]}
                        >
                            <Input
                                type="text"
                                placeholder="Branch"
                                // maxLength={500}
                                onKeyDown={(e) => {
                                    const allowedChars = /^[a-zA-Z\s]+$/;
                                    if (!allowedChars.test(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} ref={containerRef}>
                        <Form.Item
                            name="bankcountry"
                            label="Country"
                            rules={[{ required: false, message: "Country is required" }]}
                        >
                            <Select
                                showSearch
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                getPopupContainer={() => containerRef.current}
                            >
                                {countryList?.map((country, index) => (
                                    <Select.Option
                                        key={index}
                                        value={country}
                                        label={country}
                                    >
                                        {country}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                        <Form.Item
                            name="bankcode"
                            label="Bank code"
                            rules={[
                                {
                                    required: false,
                                    message: "Bank code is required",
                                },
                            ]}
                        >
                            <Input
                                type="text"
                                placeholder="Bank code"
                                maxLength={25}
                            // onKeyDown={(e) => {
                            //   const allowedChars = /^[a-zA-Z\s]+$/;
                            //   if (!allowedChars.test(e.key)) {
                            //     e.preventDefault();
                            //   }
                            // }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                        <Form.Item
                            name="bankbranchcode"
                            label="Branch Code"
                            rules={[
                                {
                                    required: false,
                                    message: "Branch Code is required",
                                },
                            ]}
                        >
                            <Input
                                type="text"
                                placeholder="Branch Code"
                                maxLength={25}
                            // onKeyDown={(e) => {
                            //   const allowedChars = /^[a-zA-Z\s]+$/;
                            //   if (!allowedChars.test(e.key)) {
                            //     e.preventDefault();
                            //   }
                            // }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                        <Form.Item
                            name="bankswiftcode"
                            label="Swiftcode"
                            rules={[
                                {
                                    required: false,
                                    message: "Swiftcode is required",
                                },
                            ]}
                        >
                            <Input
                                type="text"
                                placeholder="Swiftcode"
                                maxLength={25}
                            // onKeyDown={(e) => {
                            //   const allowedChars = /^[a-zA-Z\s]+$/;
                            //   if (!allowedChars.test(e.key)) {
                            //     e.preventDefault();
                            //   }
                            // }}
                            />
                        </Form.Item>
                    </Col>
                </StyleRow>

                <b className="my-2 mx-1">Banking Information</b>
                <StyleRow className="m-1 mt-2 mb-3" gutter={20}>
                    <Col xs={24} sm={24} md={12}>
                        <Form.Item name="krapin" label="KRA PIN Number">
                            <Input
                                placeholder="KRA PIN Number"
                                maxLength={20}
                                onKeyDown={(e) => {
                                    const value =
                                        /^[0-9]$/?.test(e.key) || /^[a-zA-Z\s]+$/?.test(e.key);
                                    if (!value && e.key !== "Backspace" && /^[0-9]$/) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                        <Form.Item name="nssf" label="NSSF Number">
                            <Input
                                placeholder="NSSF Number"
                                maxLength={20}
                                onKeyDown={(e) => {
                                    const value =
                                        /^[0-9]$/?.test(e.key) || /^[a-zA-Z\s]+$/?.test(e.key);
                                    if (!value && e.key !== "Backspace" && /^[0-9]$/) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                        <Form.Item name="nhif" label="NHIF Number">
                            <Input
                                placeholder="NHIF Number"
                                maxLength={20}
                                onKeyDown={(e) => {
                                    const value =
                                        /^[0-9]$/?.test(e.key) || /^[a-zA-Z\s]+$/?.test(e.key);
                                    if (!value && e.key !== "Backspace" && /^[0-9]$/) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                        <Form.Item name="helpmonthlybilling" label="HELB monthly billing (KES)">
                            <Input
                                placeholder="HELB monthly billing (KES)"
                                maxLength={20}
                                onKeyDown={(e) => {
                                    const value =
                                        /^[0-9]$/?.test(e.key) || /^[a-zA-Z\s]+$/?.test(e.key);
                                    if (!value && e.key !== "Backspace" && /^[0-9]$/) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col>
                </StyleRow>

                <StyleRow1>
                    {/* <p>Note: The data provided by you in this form will only be used for official purposes, notably payroll processing and reaching your next of kin in case of an emergency.</p>
                    <p>Employee’s consent: Kindly indicate your consent for the use of the data as noted above.</p>
                    <label>Date:</label> */}
                    <br />
                    {/* <Col xs={24} sm={24} md={24}>
                        <Form.Item name="signdate" label="Date">
                            <DatePicker
                                className="w-100"
                                format="DD-MM-YYYY"
                                placeholder="Select "
                                getPopupContainer={() => containerRef.current}
                                style={{ maxWidth: "400px" }}
                            />

                        </Form.Item>
                    </Col>

                    
                    <Col xs={24} sm={24} md={24}>
                        <Form.Item name="sign" label="Signature">
                            <Upload
                                maxCount={1}
                                style={{ width: "400px" }}
                                customRequest={({ file, onSuccess }) => {
                                    const reader = new FileReader();
                                    reader.readAsDataURL(file);

                                    reader.onload = () => {
                                        const base64Data =
                                            reader.result.split(",")[1];
                                          const uniqueId = generateUniqueId();
                                          onSuccess({ file, base64Data, uniqueId });
                                    };

                                    reader.onerror = (error) => {
                                        console.error("File read error:", error);
                                    };
                                }}
                                name="logo"
                                action="/upload.do"
                                  onChange={(value) => {
                                    //  onFinish();
                                    uploadFile(value);
                                  }}
                                listType="picture"
                            >
                                <div style={{ width: "100%", margin: "20px 0" }}>
                                    <Button
                                        className="w-100 text-start"
                                        size="large"
                                        style={{ maxWidth: "400px" }}
                                    >
                                        Upload Signature
                                    </Button>
                                </div>
                            </Upload>

                        </Form.Item>
                    </Col> */}
                   
                </StyleRow1>
            </>

        </div >
    )
}

export default BankInformation