import React, { useEffect, useState } from "react";
import StepsProgress from "./StepsProgress";
import PersonalForm from "./PersonalForm";
import EducationInfo from "./EducationInfo";
import WorkInFormation from "./WorkInformation";
import BankInformation from "./BankInformation";
import { Button, Checkbox, Form, message, Modal, notification } from "antd";
import moment from "moment";
import { CALL_API } from "common/API";
import { jwtDecode } from "jwt-decode";
import styled from "styled-components";
import axios from "axios";
import { SERVICE_URL, Initialvalues } from "common/Constants";
import { Spin } from "antd";
import logo from "../../assets/InsytLogo.svg";
import logo2 from "../../assets/BDO.svg";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import WorkDetails from "./WorkDetails";
import dayjs from "dayjs";
import ViewMore from "./ViewMore";

function AddUser({ isSubmitted, setIsSubmitted }) {
  const [step, setStep] = useState(0);
  const [showLoader, toggleLoader] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [userData, setUserData] = useState({});
  const [userDataModal, setUserDataModal] = useState(false);
  const [userDataModal1, setUserDataModal1] = useState(false);
  const [submitBtn, setSubmitBtn] = useState(false);
  const [form] = Form.useForm();
  const [signUniqueId, setSignUniqueId] = useState("");
  const [supervisorListData, setSupervisorListData] = useState([]);
  const [submitType, setSubmitType] = useState("");
  const location = useLocation();
  const [cvData, setCvData] = useState([]);
  const [validationErrorModalVisible, setValidationErrorModalVisible] =
    useState(false);
  const stepdata = [
    { id: 1, title: "" },
    { id: 2, title: "" },
    { id: 3, title: "" },
    { id: 4, title: "" },
  ];
  const StyledButton = styled(Button)`
    margin: 10px;
    margin-left: 0px;
    background: #26a4ff;
    border-radius: 5px;
    color: #ffffff;
    font-weight: bold;
    width: 100px;

    &.disabled_btn {
      background: #d3d3d3cc !important;
      cursor: not-allowed;
      color: #00000054 !important;
    }
  `;
  const StyledModal = styled(Modal)`
    .ant-modal-content {
      display: contents;
      text-align: center;
      background: #8c8c8c;
      box-shadow: none;
    }
  `;
  const StyledSuccessModal1 = styled(Modal)`
    .success_p {
      font-size: 1rem;
      text-align: center;
    }
    .ant-modal-body {
      font-weight: bold;
      padding: 20px 10px;
    }
    .ant-modal-footer {
      border-top: none;
      padding: 0px;
      margin: 0px;
    }
    .anticon-check-circle {
      font-size: 4rem;
      display: block;
      padding: 10px;
      color: #2cff00;
    }
  `;
  const StyleSubmitButton1 = styled(Button)`
    font-weight: bold;
    height: 40px;
    width: 45%;
    border-radius: 12px;
    color: #000000;
  `;
  const LogoContainer = styled.div`
    background: rgb(243, 254, 250);
    /* position: absolute; */
    top: 0;
    left: 0;
    .logo {
      width: 100%;
      max-width: 130px;
      margin: 0 auto;
      padding: 20px 15px 20px 15px;
      padding-bottom: 0px;
    }
    .logo2 {
      width: 100%;
      max-width: 115px;
      margin: 0 auto;
      padding: 10px;
    }
  `;
  const StyledSuccessModal = styled(Modal)`
    .ant-modal-body {
      font-weight: bold;
      padding: 20px 10px;
      .loading-message {
        padding-left: 50px;
      }
    }
    .ant-modal-footer {
      border-top: none;
      padding: 0px;
      margin: 0px;
    }
    .anticon-check-circle {
      font-size: 4rem;
      display: block;
      padding: 10px;
      color: #2cff00;
    }
    .buttons_container {
      display: flex;
      justify-content: center;
      gap: 1rem;
    }
    .modal_title {
      width: 90%;
      margin: auto;
      text-align: center;
    }
  `;

  const getMasterData = async () => {
    try {
      if (!isSubmitted) {
        setDataLoading(true);
      }
      const queryParams = queryString.parse(location.search);
      const response = await axios.get(
        `${SERVICE_URL}onboarded-users?userid=${queryParams.userid}`
      );
      const savedData = await axios.get(
        `${SERVICE_URL}onboarded-users/draft?userid=${queryParams.userid}`
      );
      let updatedSaved = {
        ...response?.data?.response?.response?.user,
        ...savedData?.data?.response?.response,
      };
      // let newCv = savedData?.data?.response?.response?.cv || [];
      // const transformedFileData = newCv.map(file => ({
      //   uid: `rc-upload-${Date.now()}-${file.documentId}`, // Generates unique UID
      //   size: file.fileSize ? parseInt(file.fileSize) : 8616, // Assuming default file size if not available
      //   lastModifiedDate: new Date(file.createdon).toISOString(), // Converts createdon to ISO format
      //   name: file.documentName,
      //   lastModified: new Date(file.createdon).getTime(), // Converts createdon to timestamp
      //   type: file.type, // MIME type remains the same
      //   percent: 0, // Upload percentage is set to 0 (assuming file is done)
      //   originFileObj: {
      //     uid: `rc-upload-${Date.now()}-${file.documentId}`
      //   }
      // }));
      // setCvData(transformedFileData|| []);
      if (
        response.data.response.error === "User not found" &&
        response.status === 200
      ) {
        notification.error({ message: "No User Data Found" });
      }
      if (
        response?.data?.response?.response?.user?.onBoardingFormStatus === "0"
      ) {
        message.error("You have already updated your details");
        setDataLoading(false);
        setSubmitBtn(true);
        setUserData(updatedSaved);
        setSupervisorListData(
          response?.data?.response?.response?.supervisorList
        );
      } else {
        setUserData(updatedSaved);
        setSupervisorListData(
          response?.data?.response?.response?.supervisorList
        );
        setDataLoading(false);
      }
    } catch (error) {
      console.error("Error fetching master data:", error);
      notification.error({ message: "No User Data Found" });
      setDataLoading(false);
    }
  };
  useEffect(() => {
    getMasterData();
  }, []);

  const saveHandler = async () => {
    setUserDataModal1(false);
    let values = form.getFieldsValue();
    try {
      toggleLoader(true);
      setDataLoading(true);

      const {
        firstname,
        lastname,
        initial,
        salutation,
        othernames,
        dateofbirth,
        gender,
        dependent,
        maritalstatus,
        phoneno,
        user_id,
        postaladdress,
        physicaladdress,
        country,
        presentaddress,
        address,
        greetings,
        national_id,
        nationality,
        workpermitno,
        workpermitexpirydate,
        emergencycontactname,
        emergencycontactnumber,
        nextofkinrelationship,
        nextofkinpostaladdress,
        educationdetails,
        practicaltrainings,
        professionalcertifications,
        workexperience,
        accountname,
        accountno,
        bankname,
        bankbranch,
        bankcountry,
        bankcode,
        bankbranchcode,
        bankswiftcode,
        nssf,
        krapin,
        nhif,
        wcf,
        national_ID_Passport,
        tin,
        pssa,
        shi,
        rssb,
        nif,
        nifFile,
        inss,
        inssFile,
        nhi,
        nhiFile,
        helpmonthlybilling,
        signdate,
        companyname,
        industry,
        secondaryindustry,
        sector,
        industryrole,
        regionalsupervisor,
        regionalcompetencyrole,
        officesupervisor,
        engagementsupervisor,
        expertise,
        designation,
        grade,
        targetchargeability,
        chargeoutrate,
        lastpromotionlevel,
        lastpromotion,
        jobtitle,
        professionalsince,
        hireddate,
        employeesponser,
        relevent,
        statusname,
        description,
        workpermitexpirydateisapplicable,
        workpermitnoisapplicable,
        krapinFile,
        nhifFile,
        nssfFile,
        wcfFile,
        nationIDFile,
        tinFile,
        pssaFile,
        shiFile,
        rssbFile,
        cv,
        pension,
        pensionFile,
      } = values;

      let userId = form.getFieldValue("user_id");
      let userIdWithoutDomain = "";
      if (userId && userId.includes("@")) {
        let splitUserId = userId.split("@");
        userIdWithoutDomain = splitUserId[0];
      } else {
        console.error("Invalid user ID format or user ID is undefined");
        // You can handle the error here, for example, by setting a default value or throwing an error
      }
      // let splitUserId = userId?.split("@");
      // let userIdWithoutDomain = splitUserId[0];
      const dobDate = new Date(dateofbirth);
      const currentDate = new Date();
      const agecalculte = currentDate.getFullYear() - dobDate.getFullYear();

      const workexperienceDetails = workexperience?.map((item) => {
        const { fromdate, todate, attachment, ...rest } = item;
        const formattedFromDate = fromdate
          ? dayjs(fromdate).format("YYYY-MM-DD")
          : "";
        const formattedToDate = todate
          ? dayjs(todate).format("YYYY-MM-DD")
          : "";
        const updatedAttachment = attachment?.map((att) => ({
          name: att?.name,
          uniqueId: att?.response?.uniqueId,
        }));
        return {
          ...rest,
          fromdate: formattedFromDate,
          todate: formattedToDate,
          attachment: updatedAttachment,
        };
      });

      const transformedEducationDetails = educationdetails?.map((item) => {
        const { attachment, fromdate, todate, ...rest } = item;
        const formattedFromDate = fromdate
          ? dayjs(fromdate).format("YYYY-MM-DD")
          : "";
        const formattedToDate = todate
          ? dayjs(todate).format("YYYY-MM-DD")
          : "";
        const updatedAttachment = attachment?.map((att) => ({
          name: att?.name,
          uniqueId: att?.response?.uniqueId,
        }));
        return {
          ...rest,
          fromdate: formattedFromDate,
          todate: formattedToDate,
          attachment: updatedAttachment,
        };
      });

      const practicaltrainingsDetails = practicaltrainings?.map((item) => {
        const { fromdate, todate, ...rest } = item;
        const formattedFromDate = fromdate
          ? dayjs(fromdate).format("YYYY-MM-DD")
          : "";
        const formattedToDate = todate
          ? dayjs(todate).format("YYYY-MM-DD")
          : "";
        return {
          ...rest,
          fromdate: formattedFromDate,
          todate: formattedToDate,
        };
      });
      const professionalcertificationsDetails = professionalcertifications?.map(
        (item) => {
          const { attachment, fromdate, todate, ...rest } = item;
          const formattedFromDate = fromdate
            ? dayjs(fromdate).format("YYYY-MM-DD")
            : "";
          const formattedToDate = todate
            ? dayjs(todate).format("YYYY-MM-DD")
            : "";
          const updatedAttachment = attachment?.map((att) => ({
            name: att?.name,
            uniqueId: att?.response?.uniqueId,
          }));
          return {
            ...rest,
            fromdate: formattedFromDate,
            todate: formattedToDate,
            attachment: updatedAttachment,
          };
        }
      );
      let obj = {
        age: agecalculte,
        firstname: firstname,
        lastname: lastname,
        salutation: salutation,
        othernames: othernames || "",
        dateofbirth: dayjs(dobDate).format("YYYY-MM-DD"),
        gender: gender,
        dependent: dependent,
        maritalstatus: maritalstatus,
        phoneno: phoneno,
        user_id: user_id,
        userid: user_id,
        postaladdress: postaladdress,
        physicaladdress: physicaladdress,
        country: country,
        greetings: greetings,
        national_id: national_id,
        nationality: nationality,
        workpermitno: workpermitno,
        workpermitnoisapplicable: workpermitnoisapplicable || false,
        workpermitexpirydateisapplicable:
          workpermitexpirydateisapplicable || false,
        workpermitexpirydate: workpermitexpirydate
          ? dayjs(workpermitexpirydate).format("YYYY-MM-DD")
          : "",
        krapin: krapin,
        nssf: nssf,
        nhif: nhif,
        tin: tin,
        pssa: pssa,
        shi: shi,
        rssb: rssb,
        wcf: wcf,
        nif,
        nifFile,
        inss,
        inssFile,
        nhi,
        nhiFile,
        emergencycontactname: emergencycontactname,
        emergencycontactnumber: emergencycontactnumber,
        nextofkinrelationship: nextofkinrelationship,
        nextofkinpostaladdress: nextofkinpostaladdress,
        companyname: companyname,
        industry: industry,
        secondaryindustry,
        sector,
        industryrole,
        regionalsupervisor,
        regionalcompetencyrole: regionalcompetencyrole || "",
        officesupervisor,
        engagementsupervisor,
        expertise,
        designation,
        grade,
        targetchargeability,
        chargeoutrate,
        lastpromotionlevel,
        lastpromotion,
        jobtitle,
        professionalsince: professionalsince
          ? dayjs(professionalsince).format("YYYY-MM-DD")
          : "",
        hireddate: hireddate ? dayjs(hireddate).format("YYYY-MM-DD") : "",
        employeesponser,
        relevent: relevent || "",
        statusname,
        krapinFile: krapinFile,
        nhifFile: nhifFile,
        nssfFile,
        wcfFile,
        nationIDFile,
        tinFile,
        pssaFile,
        shiFile,
        rssbFile,
        cv: cvData?.length != 0 ? [cvData] : cv,
        educationdetails: transformedEducationDetails,
        professionalcertifications: professionalcertificationsDetails,
        practicaltrainings: practicaltrainingsDetails,
        workexperience: workexperienceDetails,

        // age: agecalculte,
        address: presentaddress,
        presentaddress: presentaddress || "",

        // othernames: othernames,
        // accountno: accountno,
        // accountname: accountname,
        // bankname: bankname,
        // bankbranch: bankbranch,
        // bankcountry: bankcountry,
        // bankcode: bankcode,
        // bankbranchcode: bankbranchcode,
        // bankswiftcode: bankswiftcode,
        onboardingdate: new Date() || "",
        signature: signUniqueId,
        method: "individualuserform",
        onBoardingFormStatus: "0",
        description,
        pension: pension,
        pensionFile: pensionFile,
      };

      const response = await CALL_API(`onboarded-users/draft`, "post", obj);

      toggleLoader(false);
      setDataLoading(false);

      // form.resetFields();
      // setIsSubmitted(true);
      // setSubmitBtn(true);
      // window.localStorage.removeItem("token");
      // sessionStorage.removeItem("tokenTimestamp");
      if (response.code === "SUCCESS") {
        if (response?.message === "Please Upgrade Your Plan") {
          message.error(response?.message);
        } else {
          message.success("Details saved");
          setStep(0);
          setFormValues({});
        }
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const goBack = () => {
    setStep(step - 1);
  };

  const handleSubmit = (values) => {
    if (!submitBtn) {
      setUserDataModal(true);
    }
    return false;
  };
  const handleValidationFailed = (errorInfo) => {
    setValidationErrorModalVisible(true);
  };

  const handleConfirmation = async () => {
    setUserDataModal(false); // Close the modal
    await userDataUpdate(form.getFieldsValue());
  };

  const userDataUpdate = async (values) => {
    try {
      toggleLoader(true);
      setDataLoading(true);

      const {
        firstname,
        lastname,
        initial,
        salutation,
        othernames,
        dateofbirth,
        gender,
        dependent,
        maritalstatus,
        phoneno,
        user_id,
        postaladdress,
        physicaladdress,
        country,
        presentaddress,
        address,
        greetings,
        national_id,
        nationality,
        workpermitno,
        workpermitexpirydate,
        emergencycontactname,
        emergencycontactnumber,
        nextofkinrelationship,
        nextofkinpostaladdress,
        educationdetails,
        practicaltrainings,
        professionalcertifications,
        workexperience,
        accountname,
        accountno,
        bankname,
        bankbranch,
        bankcountry,
        bankcode,
        bankbranchcode,
        bankswiftcode,
        nssf,
        krapin,
        nhif,
        national_ID_Passport,
        tin,
        pssa,
        shi,
        rssb,
        nif,
        nifFile,
        inss,
        inssFile,
        nhi,
        nhiFile,
        helpmonthlybilling,
        signdate,
        companyname,
        industry,
        secondaryindustry,
        sector,
        industryrole,
        regionalsupervisor,
        regionalcompetencyrole,
        officesupervisor,
        engagementsupervisor,
        expertise,
        designation,
        grade,
        targetchargeability,
        chargeoutrate,
        lastpromotionlevel,
        lastpromotion,
        jobtitle,
        professionalsince,
        hireddate,
        employeesponser,
        relevent,
        statusname,
        description,
        workpermitexpirydateisapplicable,
        workpermitnoisapplicable,
        krapinFile,
        nhifFile,
        nssfFile,
        wcfFile,
        wcf,
        nationIDFile,
        tinFile,
        pssaFile,
        shiFile,
        rssbFile,
        cv,
        pensionFile,
        pension,
      } = values;

      let userId = form.getFieldValue("user_id");
      let splitUserId = userId.split("@");
      let userIdWithoutDomain = splitUserId[0];
      const dobDate = new Date(dateofbirth);
      const currentDate = new Date();
      const agecalculte = currentDate.getFullYear() - dobDate.getFullYear();

      const workexperienceDetails = workexperience?.map((item) => {
        const { fromdate, todate, ...rest } = item;
        const formattedFromDate = fromdate
          ? dayjs(fromdate).format("YYYY-MM-DD")
          : "";
        const formattedToDate = todate
          ? dayjs(todate).format("YYYY-MM-DD")
          : "";
        return {
          ...rest,
          fromdate: formattedFromDate,
          todate: formattedToDate,
        };
      });

      const transformedEducationDetails = educationdetails?.map((item) => {
        const { attachment, fromdate, todate, ...rest } = item;
        const formattedFromDate = fromdate
          ? dayjs(fromdate).format("YYYY-MM-DD")
          : "";
        const formattedToDate = todate
          ? dayjs(todate).format("YYYY-MM-DD")
          : "";
        const updatedAttachment = attachment?.map((att) => ({
          name: att?.name,
          uniqueId: att?.response?.uniqueId,
        }));
        return {
          ...rest,
          fromdate: formattedFromDate,
          todate: formattedToDate,
          attachment: updatedAttachment,
        };
      });

      const practicaltrainingsDetails = practicaltrainings?.map((item) => {
        const { fromdate, todate, ...rest } = item;
        const formattedFromDate = fromdate
          ? dayjs(fromdate).format("YYYY-MM-DD")
          : "";
        const formattedToDate = todate
          ? dayjs(todate).format("YYYY-MM-DD")
          : "";
        return {
          ...rest,
          fromdate: formattedFromDate,
          todate: formattedToDate,
        };
      });
      const professionalcertificationsDetails = professionalcertifications?.map(
        (item) => {
          const { attachment, fromdate, todate, ...rest } = item;
          const formattedFromDate = fromdate
            ? dayjs(fromdate).format("YYYY-MM-DD")
            : "";
          const formattedToDate = todate
            ? dayjs(todate).format("YYYY-MM-DD")
            : "";
          const updatedAttachment = attachment?.map((att) => ({
            name: att?.name,
            uniqueId: att?.response?.uniqueId,
          }));
          return {
            ...rest,
            fromdate: formattedFromDate,
            todate: formattedToDate,
            attachment: updatedAttachment,
          };
        }
      );

      let obj = {
        age: agecalculte,
        user_id: user_id,
        firstname: firstname,
        lastname: lastname,
        salutation: salutation,
        othernames: othernames || "",
        dateofbirth: dayjs(dobDate).format("YYYY-MM-DD"),
        gender: gender,
        dependent: dependent,
        maritalstatus: maritalstatus,
        phoneno: phoneno,
        // user_id: user_id,
        // userid: user_id,
        postaladdress: postaladdress,
        physicaladdress: physicaladdress,
        country: country,
        greetings: greetings,
        national_id: national_id,
        nationality: nationality,
        workpermitno: workpermitno,
        workpermitnoisapplicable: workpermitnoisapplicable || false,
        workpermitexpirydateisapplicable:
          workpermitexpirydateisapplicable || false,
        workpermitexpirydate: workpermitexpirydate
          ? dayjs(workpermitexpirydate).format("YYYY-MM-DD")
          : "",
        krapin: krapin,
        nssf: nssf,
        nhif: nhif,
        tin: tin,
        pssa: pssa,
        shi: shi,
        rssb: rssb,
        wcf: wcf,
        nif,
        nifFile,
        inss,
        inssFile,
        nhi,
        nhiFile,
        nationalID: national_ID_Passport,
        emergencycontactname: emergencycontactname,
        emergencycontactnumber: emergencycontactnumber,
        nextofkinrelationship: nextofkinrelationship,
        nextofkinpostaladdress: nextofkinpostaladdress,
        companyname: companyname,
        industry: industry,
        secondaryindustry,
        sector,
        industryrole,
        regionalsupervisor,
        regionalcompetencyrole: regionalcompetencyrole || "",
        officesupervisor,
        engagementsupervisor,
        expertise,
        designation,
        grade,
        targetchargeability,
        chargeoutrate,
        lastpromotionlevel,
        lastpromotion,
        jobtitle,
        professionalsince: professionalsince
          ? dayjs(professionalsince).format("YYYY-MM-DD")
          : "",
        hireddate: hireddate ? dayjs(hireddate).format("YYYY-MM-DD") : "",
        employeesponser,
        relevent: relevent || "",
        statusname,
        krapinFile: krapinFile,
        nhifFile: nhifFile,
        wcfFile: wcfFile,
        nationIDFile: nationIDFile,
        tinFile: tinFile,
        pssaFile: pssaFile,
        shiFile: shiFile,
        rssbFile: rssbFile,
        nssfFile,
        cv: cvData?.length != 0 ? [cvData] : cv,
        educationdetails: transformedEducationDetails,
        professionalcertifications: professionalcertificationsDetails,
        practicaltrainings: practicaltrainingsDetails,
        workexperience: workexperienceDetails,
        address: presentaddress,
        presentaddress: presentaddress || "",
        onboardingdate: new Date() || "",
        signature: signUniqueId,
        method: "individualuserform",
        onBoardingFormStatus: "0",
        description,
        pensionFile: pensionFile,
        pension: pension,
      };

      const response = await CALL_API(`add-users/WASZS4S`, "patch", obj);
      toggleLoader(false);
      setDataLoading(true);
      // form.resetFields();

      setSubmitBtn(true);
      saveHandler();
      // setIsSubmitted(true);
      // window.localStorage.removeItem("token");
      // sessionStorage.removeItem("tokenTimestamp");
      if (response.code === "SUCCESS") {
        if (response?.message === "Please Upgrade Your Plan") {
          message.error(response?.message);
        } else {
          message.success(response?.message);
          setStep(0);
          setFormValues({});
        }
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      firstname: userData?.firstname,
      statusname:
        userData?.statusname?.charAt(0).toUpperCase() +
          userData?.statusname?.slice(1) || "",
      lastname: userData?.lastname,
      salutation: userData?.salutation,
      initial: userData?.initial || "",
      othernames: userData?.othernames,
      dateofbirth: userData?.dateofbirth ? dayjs(userData.dateofbirth) : "",
      gender: userData?.gender || "",
      dependent: userData?.dependent || "",
      maritalstatus: userData?.maritalstatus,
      phoneno: userData?.phoneno,
      user_id: userData?.userid,
      postaladdress: userData?.postaladdress,
      physicaladdress: userData?.physicaladdress,
      address: userData?.address || userData?.physicaladdress || "",
      presentaddress: userData?.presentaddress || userData?.address,
      password: userData?.password,
      country: userData?.country || "",
      greetings: userData?.greetings,
      national_id: userData?.national_id,
      nationality: userData?.nationality,
      workpermitno: userData?.workpermitno,
      workpermitnoisapplicable: false,
      workpermitexpirydate: userData?.workpermitexpirydate
        ? dayjs(userData.workpermitexpirydate)
        : "",
      workpermitexpirydateisapplicable: false,
      emergencycontactname: userData?.emergencycontactname,
      emergencycontactnumber: userData?.emergencycontactnumber,
      nextofkinrelationship: userData?.nextofkinrelationship,
      nextofkinpostaladdress: userData?.nextofkinpostaladdress,
      educationdetails: userData?.educationdetails
        ? userData?.educationdetails?.map((detail) => ({
            institutename: detail.institutename,
            fromdate: detail.fromdate ? dayjs(detail.fromdate) : "",
            todate: detail.todate ? dayjs(detail.todate) : "",
            exambody: detail.exambody,
            awarded: detail.awarded,
            attachment: detail.attachment,
          }))
        : Initialvalues?.educationdetails,
      practicaltrainings: userData?.practicaltrainings
        ? userData?.practicaltrainings?.map((training) => ({
            organisationname: training.organisationname,
            fromdate: training.fromdate ? dayjs(training.fromdate) : "",
            todate: training.todate ? dayjs(training.todate) : "",
            positionheld: training.positionheld,
            tasksperformed: training.tasksperformed,
          }))
        : Initialvalues?.practicaltrainings,
      professionalcertifications: userData?.professionalcertifications
        ? userData?.professionalcertifications?.map((cert) => ({
            certificationtype: cert.certificationtype,
            fromdate: cert.fromdate ? dayjs(cert.fromdate) : "",
            todate: cert.todate ? dayjs(cert.todate) : "",
            remarks: cert.remarks,
            attachment: cert.attachment,
          }))
        : Initialvalues?.professionalcertifications,
      workexperience: userData?.workexperience
        ? userData?.workexperience?.map((exp) => ({
            companyname: exp.companyname,
            fromdate: exp.fromdate ? dayjs(exp.fromdate) : "",
            todate: exp.todate ? dayjs(exp.todate) : "",
            jobtitle: exp.jobtitle,
            relevant: exp.relevant,
            jobdescription: exp.jobdescription,
            attachment: exp.attachment,
          }))
        : Initialvalues?.workexperience,
      accountname: userData?.accountname,
      accountno: userData?.accountno,
      bankname: userData?.bankname,
      bankbranch: userData?.bankbranch,
      bankcountry: userData?.bankcountry,
      bankcode: userData?.bankcode,
      bankbranchcode: userData?.bankbranchcode,
      bankswiftcode: userData?.bankswiftcode,
      cv: userData?.cv || [],
      krapin: userData?.krapin,
      krapinFile: userData?.krapinFile || [],
      nssf: userData?.nssf,
      nssfFile: userData?.nssfFile || [],
      nhif: userData?.nhif,
      nhifFile: userData?.nhifFile || [],
      wcf: userData?.wcf,
      wcfFile: userData?.wcfFile || [],
      nationalID: userData?.national_ID_Passport,
      nationIDFile: userData?.nationIDFile || [],
      tin: userData?.tin,
      tinFile: userData?.tinFile || [],
      pensionFile: userData?.pensionFile || [],
      pension: userData?.pension,
      pssa: userData?.pssa,
      pssaFile: userData?.pssaFile || [],
      shi: userData?.shi,
      shiFile: userData?.shiFile || [],
      rssb: userData?.rssb,
      rssbFile: userData?.rssbFile || [],
      nif: userData?.nif,
      nifFile: userData?.nifFile || [],
      inss: userData?.inss,
      inssFile: userData?.inssFile || [],
      nhi: userData?.nhi,
      nhiFile: userData?.nhiFile || [],
      helpmonthlybilling: userData?.helpmonthlybilling,
      companyname: userData?.companyname,
      industry: userData?.industry,
      secondaryindustry: userData?.secondaryindustry,
      sector: userData?.sector,
      industryrole: userData?.industryrole,
      regionalsupervisor: userData?.regionalsupervisor,
      regionalcompetencyrole: userData?.regionalcompetencyrole,
      officesupervisor: userData?.officesupervisor,
      engagementsupervisor: userData?.engagementsupervisor,
      expertise: userData?.expertise,
      designation: userData?.designation,
      grade: userData?.grade,
      targetchargeability: userData?.targetchargeability,
      chargeoutrate: userData?.chargeoutrate,
      lastpromotionlevel: userData?.lastpromotionlevel
        ? dayjs(userData?.lastpromotionlevel)
        : "",
      lastpromotion: userData?.lastpromotion,
      jobtitle: userData?.jobtitle,
      professionalsince: userData?.professionalsince
        ? dayjs(userData?.professionalsince)
        : "",
      hireddate: userData?.hireddate ? dayjs(userData?.hireddate) : "",
      employeesponser: userData?.employeesponser,
      relevent: userData?.relevent,
      description: userData?.description,
    });
  }, [userData]);

  const closeBtnHandler = () => {
    setIsSubmitted(false);
    setSubmitBtn(true);
    setDataLoading(false);
  };

  return (
    <>
      <LogoContainer className="mb-2 pt-3 pb-3">
        <div className="container">
          <img className="logo " src={logo2} alt="logo" />
        </div>
        {/* <div className="text-center ">
              <img className="logo2" src={logo2} alt="logo2" />
            </div> */}
        <h4 className="text-center pb-2">Employee Details form</h4>
      </LogoContainer>
      <div className="container">
        <StyledModal
          title=""
          visible={dataLoading}
          centered
          closable={false}
          maskClosable={false}
          // width={350}
          footer={[]}
          onCancel={() => setDataLoading(false)}
        >
          <Spin size="large" />
        </StyledModal>
        <Modal
          visible={validationErrorModalVisible}
          title="Form Incomplete"
          onOk={() => setValidationErrorModalVisible(false)}
          onCancel={() => setValidationErrorModalVisible(false)}
          footer={[
            <Button
              key="ok"
              type="primary"
              onClick={() => setValidationErrorModalVisible(false)}
            >
              OK
            </Button>,
          ]}
        >
          <p>Please fill all the required fields before submitting the form.</p>
        </Modal>
        {/* <StepsProgress stepdata={stepdata} title="" step={step} /> */}
        <Form
          layout="vertical"
          onFinish={handleSubmit}
          form={form}
          onFinishFailed={handleValidationFailed}
        >
          <PersonalForm
            setStep={setStep}
            setFormValues={setFormValues}
            formValues={formValues}
            form={form}
          />
          <WorkDetails
            setStep={setStep}
            supervisorList={supervisorListData}
            setFormValues={setFormValues}
            formValues={formValues}
            userData={userData}
            form={form}
            setDataLoading={setDataLoading}
            setCvData={setCvData}
          />

          <EducationInfo
            goBack={goBack}
            setStep={setStep}
            setFormValues={setFormValues}
            formValues={formValues}
            form={form}
          />
          <WorkInFormation
            goBack={goBack}
            setStep={setStep}
            setFormValues={setFormValues}
            formValues={formValues}
            form={form}
          />
          {/* <BankInformation
          goBack={goBack}
          setFormValues={setFormValues}
          handleSubmit={handleSubmit}
          formValues={formValues}
          form={form}
          showLoader={showLoader}
          setSignUniqueId={setSignUniqueId}
        /> */}
          <Form.Item
            name="acceptTerms"
            className="ms-0"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(
                        "You must accept the terms and conditions"
                      ),
              },
            ]}
          >
            <Checkbox>
              <ViewMore
                value={`By submitting this form, I confirm that the information provided is true.
I consent to having my personal details processed.
I agree that BDO East Africa and its affiliated units (as data processor and/or controller) may process my data as needed in the course of its operations and my employment/contract, in compliance with the data protection laws and all other applicable legislations/ regulations in relation to the personal data.
BDO commits to ensure necessary safeguards are in place during storage, processing, and/or transfer of confidential information.`}
                count={200}
              />
            </Checkbox>
          </Form.Item>
          <StyledButton
            type="primary"
            htmlType="button"
            onClick={() => {
              if (!submitBtn) {
                setUserDataModal1(true);
              }
            }}
            className={submitBtn ? "disabled_btn" : ""}
          >
            Save
          </StyledButton>
          <StyledButton
            type="primary"
            htmlType="submit"
            // onClick={() => setSubmitType("submit")}
            className={submitBtn ? "disabled_btn" : ""}
          >
            Submit
          </StyledButton>

          <StyledSuccessModal
            title=""
            visible={userDataModal}
            centered
            closable={true}
            keyboard={true}
            maskClosable={true}
            // width={350}
            footer={[]}
            onCancel={() => setUserDataModal(false)}
          >
            <p className="modal_title mb-3">
              Please make sure that all this data is correct and genuine, as we
              consider this for BDO HR purposes.
            </p>
            <div className="buttons_container">
              <StyledButton
                type="primary"
                htmlType="button"
                onClick={() => setUserDataModal(false)}
              >
                Cancel
              </StyledButton>
              <StyledButton
                loading={showLoader}
                type="primary"
                htmlType="button"
                onClick={handleConfirmation}
              >
                Submit
              </StyledButton>
            </div>
          </StyledSuccessModal>
          <StyledSuccessModal
            title=""
            visible={userDataModal1}
            centered
            closable={true}
            keyboard={true}
            maskClosable={true}
            // width={350}
            footer={[]}
            onCancel={() => setUserDataModal1(false)}
          >
            <p className="modal_title mb-3">
              Are you sure want to save the details for later?
            </p>
            <div className="buttons_container">
              <StyledButton
                type="primary"
                htmlType="button"
                onClick={() => setUserDataModal1(false)}
              >
                Cancel
              </StyledButton>
              <StyledButton
                loading={showLoader}
                type="primary"
                htmlType="button"
                onClick={saveHandler}
              >
                Yes
              </StyledButton>
            </div>
          </StyledSuccessModal>
          <StyledSuccessModal1
            title=""
            visible={isSubmitted}
            centered
            closable={false}
            keyboard={true}
            maskClosable={false}
            // width={350}
            footer={[]}
            onCancel={() => setIsSubmitted(false)}
          >
            <p className="success_p pb-2 px-2">
              Thank you for your valuable time in sharing the data. Please keep
              in touch with the BDO HR team if you have any concerns or queries.
            </p>
            <div className="text-center">
              <StyleSubmitButton1
                type="primary"
                htmlType="button"
                onClick={closeBtnHandler}
              >
                Close
              </StyleSubmitButton1>
            </div>
          </StyledSuccessModal1>
        </Form>
      </div>
    </>
  );
}

export default AddUser;
