import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  DatePicker,
  Select,
  message,
  Checkbox,
  Switch,
  Upload,
  Space,
  TimePicker,
  Button,
  Radio,
} from "antd";
import styled from "@emotion/styled";
import moment from "moment";
import {
  NON_EMPTY_REGEX,
  EMAIL_REGEX,
  countryList,
  Greetings,
  AwardList,
  STATUS_CODE,
  ContractingCompany,
  Industry,
  secondaryIndustry,
  Sector,
  IndustryRole,
  RegionalCometencyRole,
  Grade,
} from "common/Constants";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { isEmpty } from "lodash";
import { CALL_API } from "common/API";

const StyleRow = styled(Row)`
  padding: 10px;
  box-shadow: 0px 3px 6px #00000015;
  .ant-form-item-label {
    text-align: left;
  }
  #country {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .empty_col {
    width: 30%;
  }

  .ant-btn.ant-btn-default {
    border: none;
    background: #f4f4f4;
    color: #000;
    border-radius: 6px;
  }
  .ant-upload-list.ant-upload-list-text {
    margin-top: 20px;
  }
  .cv_file {
    .ant-upload-list.ant-upload-list-text {
      margin-top: 0px;
    }
  }
  .ant-upload-list-item.ant-upload-list-item-undefined {
    background-color: rgb(194, 250, 229);
  }
  .ant-upload-wrapper .ant-upload-list .ant-upload-list-item {
    position: relative;
    height: 35px;
    margin-top: 8px;
    padding: 5px;
    font-size: 14px;
    display: flex;
    align-items: center;
    background-color: rgb(194, 250, 229);
    transition: background-color 0.3s;
  }
  .attach_btn_cv {
    background: #c2fae5;
    margin-top: 28px;
    height: 35px;
    color: #4b4b4bc7;
    border-radius: 6px;
    padding: 5px;
  }

  .attach_btn {
    background-color: rgb(194, 250, 229);
    margin-top: 28px;
    height: 35px;
    color: #4b4b4bc7;
    border-radius: 6px;
    padding: 5px;
  }
  /* .attach_btn_2 {
    background-color: rgb(194, 250, 229) !imporatnt;
    height: 35px;
    color: #4b4b4bc7;
    border-radius: 6px;
    padding: 5px;
    ::before{
      background-color: rgb(194, 250, 229) !imporatnt;
    }
  } */
  .ant-btn-default {
    background-color: rgb(194, 250, 229) !imporatnt;
  }
  .ant-radio-button-wrapper:not(:first-child)::before {
    width: 0px;
  }

  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked.ant-radio-button-wrapper-in-form-item {
    background-color: rgb(194, 250, 229) !imporatnt;
    color: #000;
  }
  .ant-picker {
    width: 100% !important;
  }
  .ant-upload.ant-upload-select {
    width: 100% !important;
    color: #397ff4 !important;
    background-color: rgb(194, 250, 229) !imporatnt;
    .ant-btn > span {
      display: inline-block;
      color: #397ff4;
    }
    button,
    html [type="button"],
    [type="reset"],
    [type="submit"] {
      background-color: rgb(194, 250, 229) !imporatnt;
    }
    .ant-upload-list-picture .ant-upload-list-item,
    .ant-upload-list-picture-card .ant-upload-list-item {
      height: 40px;
    }
    .ant-upload-list-text .ant-upload-list-item-name,
    .ant-upload-list-picture .ant-upload-list-item-name {
      color: #397ff4;
    }
  }
  .css-1qjjar1 .ant-upload.ant-upload-select button,
  .css-1qjjar1 .ant-upload.ant-upload-select html [type="button"],
  .css-1qjjar1 .ant-upload.ant-upload-select [type="reset"],
  .css-1qjjar1 .ant-upload.ant-upload-select [type="submit"] {
    background: rgb(194, 250, 229) !imporatnt;
  }
  #country {
    width: 100%;
    display: -webkit-box;
    gap: 1rem;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .upload_Box_Style {
    background-color: rgb(194, 250, 229) !important;
  }

  @media (min-width: 600px) {
    .ant-btn.ant-btn-default {
      width: 100px;
    }
  }
  @media (max-width: 991px) {
    .empty_col {
      width: 100%;
    }
  }
`;

function WorkDetails({
  setStep,
  setFormValues,
  formValues,
  form,
  userData,
  supervisorList,
  setDataLoading,
  setCvData,
}) {
  const { Option } = Select;
  const containerRef = useRef(null);

  const [emailsList, setEmailList] = useState([]);
  const [fileList, setFileList] = useState();
  // const [formList, setFormList] = useState(form.getFieldsValue());
  const [selectedCountry, setSelectedCountry] = useState(
    userData?.country || ""
  );
  const handleUploadChange = ({ fileList, file }, documentType) => {
    // uploadFile({ fileList, file });
    uploadFile({ fileList, file }, documentType);
    setFileList(fileList);
  };
  useEffect(() => {
    setSelectedCountry(userData?.country || "");
  }, [userData]);

  const validateFiles = (rule, value) => {
    if (!value || value.length === 0) {
      return Promise.reject(new Error("File is required"));
    }

    const validTypes = [
      "application/pdf",
      "image/jpeg",
      "image/png",
      "image/gif",
    ];

    const isValid = value.every((file) => validTypes.includes(file.type));

    if (!isValid) {
      return Promise.reject(
        new Error("You can only upload PDF or image files!")
      );
    }

    return Promise.resolve();
  };

  function generateUniqueId() {
    const usedIds = [];
    let uniqueId = "";
    while (uniqueId.length !== 6) {
      const randomNumber = Math.floor(Math.random() * 900000) + 100000;
      if (!usedIds.includes(randomNumber.toString())) {
        usedIds.push(randomNumber.toString());
        uniqueId += randomNumber.toString();
      }
    }
    return uniqueId;
  }
  const uploadFile = async (value, documentType) => {
    const { file } = value;
    try {
      if (file && !isEmpty(file)) {
        // Base64 Conversion moved here
        const reader = new FileReader();

        reader.onload = async () => {
          const base64Data = reader.result.split(",")[1]; // Convert to base64
          const { userid = "" } = userData;
          const obj = {
            // userId: user_id?.toLowerCase(),
            name: file.name,
            type: file.type,
            documentType: documentType,
            documentName: documentType === "CV" ? "cv" : "identityinformation",
            uniqueId: file.uid,
            status: true,
            content: base64Data,
          };

          // setDataLoading(true);
          const { code, documentDetails } = await CALL_API(
            `upload-identity/WASZS4S/${userid}`,
            "post",
            obj
          );
          if (STATUS_CODE.SUCCESS === code && documentType === "CV") {
            let maxDocumentIdObject =
              documentDetails[documentDetails?.length - 1];

            maxDocumentIdObject.name = maxDocumentIdObject.documentName;
            maxDocumentIdObject.type = file.type;
            // maxDocumentIdObject.status = "done";
            // maxDocumentIdObject.uid = maxDocumentIdObject.documentId;
            // maxDocumentIdObject.type = "image/jpeg";
            // maxDocumentIdObject.file = "image/jpeg";

            setCvData(maxDocumentIdObject);
          }
          // setDataLoading(false);
        };

        reader.onerror = (error) => {
          console.error("FileReader error:", error);
        };

        reader.readAsDataURL(file);
      }
    } catch (error) {
      console.error("[uploadFile] error --->", error);
    }
  };

  const handleClick = (country) => {
    setSelectedCountry(country);
  };

  const getEmailList = async () => {
    const response = await CALL_API(`add-users/email-list`, "get");
    if (response.code === STATUS_CODE.SUCCESS) setEmailList(response.response);
  };

  useEffect(() => {
    getEmailList();
    if (!isEmpty(formValues)) {
      form.setFieldsValue(formValues);
    }
  }, [formValues]);
  let formList = form.getFieldsValue();
  return (
    <div>
      <>
        <b className="my-2 mx-1">Work Information:</b>
        <StyleRow className=" mt-2 mb-3" gutter={20}>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              name="companyname"
              label="Contracting Company"
              rules={[
                {
                  required: false,
                  message: "Contracting Company is required",
                },
              ]}
            >
              <Select
                disabled
                showSearch
                placeholder="Search to Select"
                optionFilterProp="children"
                getPopupContainer={() => containerRef.current}
              >
                {ContractingCompany?.map((value, index) => (
                  <Select.Option key={index} value={value} label={value}>
                    {value}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} ref={containerRef}>
            <Form.Item
              name="industry"
              label="Primary Industry"
              rules={[
                {
                  required: true,
                  message: "Primary Industry is required",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Search to Select"
                optionFilterProp="children"
                // mode="multiple"
                getPopupContainer={() => containerRef.current}
              >
                {Industry?.map((value, index) => (
                  <Select.Option key={index} value={value} label={value}>
                    {value}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} ref={containerRef}>
            <Form.Item
              name="secondaryindustry"
              label="Secondary Industry"
              rules={[
                {
                  required: true,
                  message: "Secondary Industry is required",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Search to Select"
                optionFilterProp="children"
                // mode="multiple"
                getPopupContainer={() => containerRef.current}
              >
                {secondaryIndustry?.map((value, index) => (
                  <Select.Option key={index} value={value} label={value}>
                    {value}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} ref={containerRef}>
            <Form.Item
              name="industryrole"
              label="Industry Role"
              rules={[
                {
                  required: false,
                  message: "Industry Role is required",
                },
              ]}
            >
              <Select
                disabled
                showSearch
                placeholder="Search to Select"
                optionFilterProp="children"
                getPopupContainer={() => containerRef.current}
              >
                {IndustryRole?.map((value, index) => (
                  <Select.Option key={index} value={value} label={value}>
                    {value}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} ref={containerRef}>
            <Form.Item
              name="regionalsupervisor"
              label="Regional Competency Supervisor"
              rules={[
                {
                  required: false,
                  message: "Regional Competency Supervisor is required",
                },
              ]}
            >
              <Select
                showSearch
                disabled
                placeholder="Search to Select"
                optionFilterProp="children"
                // options={managingOfficeData}
                allowClear
                getPopupContainer={() => containerRef.current}
                options={supervisorList}
                fieldNames={{
                  label: "name",
                  value: "employeeId",
                }}
                filterOption={(input, option) =>
                  option.name.toLowerCase().includes(input.toLowerCase())
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} ref={containerRef}>
            <Form.Item
              name="regionalcompetencyrole"
              label="Regional Competency Role"
              rules={[
                {
                  required: false,
                  message: "Regional Competency Role is required",
                },
              ]}
            >
              <Select
                showSearch
                disabled
                placeholder="Search to Select"
                optionFilterProp="children"
                getPopupContainer={() => containerRef.current}
              >
                {RegionalCometencyRole.map((value, index) => (
                  <Select.Option key={index} value={value} label={value}>
                    {value}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} ref={containerRef}>
            <Form.Item
              name="officesupervisor"
              label="Office Competency Supervisor"
              rules={[
                {
                  required: false,
                  message: "Office Competency Supervisor is required",
                },
              ]}
            >
              <Select
                showSearch
                disabled
                placeholder="Search to Select"
                optionFilterProp="children"
                getPopupContainer={() => containerRef.current}
                options={supervisorList}
                allowClear
                fieldNames={{
                  label: "name",
                  value: "employeeId",
                }}
                filterOption={(input, option) =>
                  option.name.toLowerCase().includes(input.toLowerCase())
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} ref={containerRef}>
            <Form.Item
              name="engagementsupervisor"
              label="Secondary Supervisor"
              rules={[
                {
                  required: false,
                  message: "Engagement Supervisor is required",
                },
              ]}
            >
              <Select
                showSearch
                disabled
                placeholder="Search to Select"
                optionFilterProp="children"
                getPopupContainer={() => containerRef.current}
                options={supervisorList}
                fieldNames={{
                  label: "name",
                  value: "employeeId",
                }}
                filterOption={(input, option) =>
                  option.name.toLowerCase().includes(input.toLowerCase())
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              name="expertise"
              label="Expertise"
              rules={[
                {
                  required: true,
                  message: "Expertise is required",
                },
              ]}
            >
              <Input
                type="text"
                placeholder="Expertise"
                // maxLength={50}
                onKeyDown={(e) => {
                  const allowedChars = /^[a-zA-Z\s]+$/;
                  if (!allowedChars.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12}>
            <Form.Item
              name="designation"
              label="Designation"
              rules={[
                {
                  required: false,
                  message: "Designation is required",
                },
              ]}
            >
              <Input
                type="text"
                disabled
                placeholder="Designation"
                maxLength={50}
                onKeyDown={(e) => {
                  const allowedChars = /^[a-zA-Z\s]+$/;
                  if (!allowedChars.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} ref={containerRef}>
            <Form.Item
              name="grade"
              label="Grade"
              rules={[
                {
                  required: false,
                  message: "Grade is required",
                },
              ]}
            >
              <Select
                showSearch
                disabled
                placeholder="Search to Select"
                optionFilterProp="children"
                getPopupContainer={() => containerRef.current}
              >
                {Grade?.map((value, index) => (
                  <Select.Option key={index} value={value} label={value}>
                    {value}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12}>
            <Form.Item
              name="targetchargeability"
              label="Target Chargeability %"
              rules={[
                {
                  required: false,
                  message: "Target Chargeability % is required",
                },
              ]}
            >
              <Input
                type="text"
                disabled
                placeholder="Target Chargeability %"
                maxLength={50}
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12}>
            <Form.Item
              name="chargeoutrate"
              label="Charge Out Rate"
              rules={[
                {
                  required: false,
                  message: "Charge Out Rate is required",
                },
              ]}
            >
              <Input
                disabled
                type="text"
                placeholder="Charge Out Rate"
                maxLength={50}
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} ref={containerRef}>
            <Form.Item
              label="Last Promotion Date"
              name="lastpromotionlevel"
              rules={[
                {
                  required: false,
                  pattern: NON_EMPTY_REGEX,
                  message: "Last Promotion Date is required",
                },
              ]}
            >
              <DatePicker
                disabled
                className="w-100"
                format="DD-MM-YYYY"
                placeholder="Select Last Promotion Date"
                getPopupContainer={() => containerRef.current}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              name="lastpromotion"
              label="Last Promotion Level"
              rules={[
                {
                  required: false,
                  message: "Last Promotion is required",
                },
              ]}
            >
              <Input
                type="text"
                disabled
                placeholder="Last Promotion"
                maxLength={100}
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12}>
            <Form.Item
              name="jobtitle"
              label="Job Title"
              rules={[
                {
                  required: false,
                  message: "Job title is required",
                },
              ]}
            >
              <Input
                type="text"
                disabled
                placeholder="Job Title"
                maxLength={50}
                onKeyDown={(e) => {
                  const allowedChars = /^[a-zA-Z\s]+$/;
                  if (!allowedChars.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} ref={containerRef}>
            <Form.Item
              label="Professional Since"
              name="professionalsince"
              rules={[
                {
                  required: true,
                  pattern: NON_EMPTY_REGEX,
                  message: "Professional Since is required",
                },
              ]}
            >
              <DatePicker
                className="w-100"
                format="DD-MM-YYYY"
                placeholder="Professional Since"
                getPopupContainer={() => containerRef.current}
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} ref={containerRef}>
            <Form.Item
              label="Hired Date"
              name="hireddate"
              rules={[
                {
                  required: false,
                  pattern: NON_EMPTY_REGEX,
                  message: "Hired Date is required",
                },
              ]}
            >
              <DatePicker
                disabled
                className="w-100"
                format="DD-MM-YYYY"
                placeholder="Select Hired Date"
                getPopupContainer={() => containerRef.current}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} ref={containerRef}>
            <Form.Item
              name="employeesponser"
              label="Employee Sponsor"
              rules={[
                {
                  required: false,
                  pattern: NON_EMPTY_REGEX,
                  message: "This field is required",
                },
              ]}
            >
              <Select
                disabled
                placeholder="Select"
                getPopupContainer={() => containerRef.current}
              >
                <Option value="yes">Yes</Option>
                <Option value="no">No</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} ref={containerRef}>
            <Form.Item
              name="relevent"
              label="Relevant"
              rules={[
                {
                  required: false,
                  pattern: NON_EMPTY_REGEX,
                  message: "This field is required",
                },
              ]}
            >
              <Select
                placeholder="Select"
                disabled
                getPopupContainer={() => containerRef.current}
              >
                <Option value="yes">Yes</Option>
                <Option value="no">No</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              name="statusname"
              label="Status"
              rules={[
                {
                  required: false,
                  message: "Status is required",
                },
              ]}
            >
              <Input
                type="text"
                disabled
                placeholder="Enter"
                maxLength={50}
                onKeyDown={(e) => {
                  const allowedChars = /^[a-zA-Z\s]+$/;
                  if (!allowedChars.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </Col>

          {/* <Col xs={24} sm={24} md={24}>
                <Form.Item name="description" label="Description">
                  <Input.TextArea
                    placeholder="description"
                  />
                </Form.Item>
              </Col> */}
        </StyleRow>
        <b className="my-2 ">Documents:</b>
        <StyleRow className="mt-2 mb-3" gutter={20}>
          <Col>
            <Form.Item
              name="country"
              label="Select country"
              rules={[{ required: true, message: "Please select a country!" }]}
            >
              <Button.Group>
                {[
                  "Kenya",
                  "Uganda",
                  "Tanzania",
                  "Rwanda",
                  "Ethiopia",
                  "Congo",
                ].map((item) => (
                  <Button
                    key={item}
                    onClick={() => {
                      handleClick(item);
                      form.setFieldValue("country", item);
                    }}
                    style={{
                      backgroundColor:
                        selectedCountry === item ? "#C2FAE5" : "#F4F4F4",
                      color: "#000",
                    }}
                    disabled={selectedCountry !== ""}
                  >
                    {item}
                  </Button>
                ))}
              </Button.Group>
            </Form.Item>
          </Col>
          <Col className="empty_col"></Col>

          {selectedCountry !== "Congo" && (
            <>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="national_id"
                  label="National ID/Passport Number:"
                  rules={[
                    {
                      required: true,
                      message: "National ID/Passport Number is required",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter No."
                    maxLength={20}
                    onKeyDown={(e) => {
                      const value =
                        /^[0-9]$/?.test(e.key) || /^[a-zA-Z\s]+$/?.test(e.key);
                      if (!value && e.key !== "Backspace" && /^[0-9]$/) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="nationIDFile"
                  // label="file"
                  valuePropName="fileList"
                  getValueFromEvent={(e) =>
                    Array.isArray(e) ? e : e && e.fileList
                  }
                  rules={[
                    {
                      required: true,
                      message: "National ID/Passport File is required",
                    },
                    {
                      validator: validateFiles,
                    },
                  ]}
                >
                  <Upload
                    accept=".pdf, image/*"
                    onRemove={() => {
                      form.resetFields(["nationIDFile"]);
                    }}
                    listType="text"
                    fileList={
                      form.getFieldValue("nationIDFile")?.fileList || []
                    }
                    onChange={(file) => handleUploadChange(file, "nationalid")}
                    beforeUpload={() => false}
                    maxCount={1}
                  >
                    {!form.getFieldValue("nationIDFile")?.length > 0 ? (
                      <div>
                        <div className="w-100 attach_btn px-3">
                          Attach File<span className="text-danger">*</span>
                        </div>
                      </div>
                    ) : null}
                  </Upload>
                </Form.Item>
              </Col>
            </>
          )}

          {selectedCountry === "Kenya" ? (
            <>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="krapin"
                  label="KRA PIN Number"
                  rules={[
                    { required: true, message: "KRA PIN Number is required" },
                  ]}
                >
                  <Input
                    placeholder="KRA PIN Number"
                    maxLength={20}
                    onKeyDown={(e) => {
                      const value =
                        /^[0-9]$/?.test(e.key) || /^[a-zA-Z\s]+$/?.test(e.key);
                      if (!value && e.key !== "Backspace" && /^[0-9]$/) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="krapinFile"
                  valuePropName="fileList"
                  getValueFromEvent={(e) =>
                    Array.isArray(e) ? e : e && e.fileList
                  }
                  rules={[
                    {
                      validator: validateFiles,
                    },
                  ]}
                >
                  <Upload
                    accept=".pdf, image/*"
                    onRemove={() => {
                      form.resetFields(["krapinFile"]);
                    }}
                    listType="text"
                    fileList={form.getFieldValue("krapinFile")?.fileList || []}
                    onChange={(file) => handleUploadChange(file, "krapin")}
                    beforeUpload={() => false}
                    maxCount={1}
                  >
                    {!form.getFieldValue("krapinFile")?.length > 0 ? (
                      <div>
                        <div className="w-100 attach_btn px-3">
                          Attach File<span className="text-danger">*</span>
                        </div>
                      </div>
                    ) : null}
                  </Upload>
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="nhif"
                  label="NHIF Number"
                  rules={[
                    { required: true, message: "NHIF Number is required" },
                  ]}
                >
                  <Input
                    placeholder="NHIF Number"
                    maxLength={20}
                    onKeyDown={(e) => {
                      const value =
                        /^[0-9]$/?.test(e.key) || /^[a-zA-Z\s]+$/?.test(e.key);
                      if (!value && e.key !== "Backspace" && /^[0-9]$/) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  // label=""
                  name="nhifFile"
                  valuePropName="fileList"
                  getValueFromEvent={(e) =>
                    Array.isArray(e) ? e : e && e.fileList
                  }
                  rules={[
                    {
                      validator: validateFiles,
                    },
                  ]}
                >
                  <Upload
                    accept=".pdf, image/*"
                    listType="text"
                    onChange={(file) => handleUploadChange(file, "nhif")}
                    onRemove={() => {
                      form.resetFields(["nhifFile"]);
                    }}
                    beforeUpload={() => false}
                    maxCount={1}
                  >
                    {!form.getFieldValue("nhifFile")?.length > 0 ? (
                      <div>
                        <div className="w-100 attach_btn px-3">
                          Attach File<span className="text-danger">*</span>
                        </div>
                      </div>
                    ) : null}
                  </Upload>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="nssf"
                  label="NSSF Number"
                  rules={[
                    { required: true, message: "NSSF Number is required" },
                  ]}
                >
                  <Input
                    placeholder="NSSF Number"
                    maxLength={20}
                    onKeyDown={(e) => {
                      const value =
                        /^[0-9]$/?.test(e.key) || /^[a-zA-Z\s]+$/?.test(e.key);
                      if (!value && e.key !== "Backspace" && /^[0-9]$/) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="nssfFile"
                  valuePropName="fileList"
                  getValueFromEvent={(e) =>
                    Array.isArray(e) ? e : e && e.fileList
                  }
                  rules={[
                    {
                      validator: validateFiles,
                    },
                  ]}
                >
                  <Upload
                    accept=".pdf, image/*"
                    listType="text"
                    // name={["name", "attachment"]}
                    onChange={(file) => handleUploadChange(file, "nssf")}
                    onRemove={() => {
                      form.resetFields(["nssfFile"]);
                    }}
                    beforeUpload={() => false}
                    maxCount={1}
                  >
                    {!form.getFieldValue("nssfFile")?.length > 0 ? (
                      <div>
                        <div className="w-100 attach_btn px-3">
                          Attach File<span className="text-danger">*</span>
                        </div>
                      </div>
                    ) : null}
                  </Upload>
                </Form.Item>
              </Col>
            </>
          ) : selectedCountry === "Uganda" ? (
            <>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="tin"
                  label="TIN Number:"
                  rules={[
                    { required: true, message: "TIN Number is required" },
                  ]}
                >
                  <Input
                    placeholder="Enter No."
                    maxLength={20}
                    onKeyDown={(e) => {
                      const value =
                        /^[0-9]$/?.test(e.key) || /^[a-zA-Z\s]+$/?.test(e.key);
                      if (!value && e.key !== "Backspace" && /^[0-9]$/) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="tinFile"
                  valuePropName="fileList"
                  getValueFromEvent={(e) =>
                    Array.isArray(e) ? e : e && e.fileList
                  }
                  rules={[
                    {
                      validator: validateFiles,
                    },
                  ]}
                >
                  <Upload
                    accept=".pdf, image/*"
                    onRemove={() => {
                      form.resetFields(["tinFile"]);
                    }}
                    listType="text"
                    fileList={form.getFieldValue("tinFile")?.fileList || []}
                    onChange={(file) => handleUploadChange(file, "tin")}
                    beforeUpload={() => false}
                    maxCount={1}
                  >
                    {!form.getFieldValue("tinFile")?.length > 0 ? (
                      <div>
                        <div className="w-100 attach_btn px-3">
                          Attach File<span className="text-danger">*</span>
                        </div>
                      </div>
                    ) : null}
                  </Upload>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="nssf"
                  label="NSSF Number:"
                  rules={[
                    { required: true, message: "NSSF Number is required" },
                  ]}
                >
                  <Input
                    placeholder="Enter No."
                    maxLength={20}
                    onKeyDown={(e) => {
                      const value =
                        /^[0-9]$/?.test(e.key) || /^[a-zA-Z\s]+$/?.test(e.key);
                      if (!value && e.key !== "Backspace" && /^[0-9]$/) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="nssfFile"
                  valuePropName="fileList"
                  getValueFromEvent={(e) =>
                    Array.isArray(e) ? e : e && e.fileList
                  }
                  rules={[
                    {
                      validator: validateFiles,
                    },
                  ]}
                >
                  <Upload
                    accept=".pdf, image/*"
                    onRemove={() => {
                      form.resetFields(["nssfFile"]);
                    }}
                    listType="text"
                    fileList={form.getFieldValue("nssfFile")?.fileList || []}
                    onChange={(file) => handleUploadChange(file, "nssf")}
                    beforeUpload={() => false}
                    maxCount={1}
                  >
                    {!form.getFieldValue("nssfFile")?.length > 0 ? (
                      <div>
                        <div className="w-100 attach_btn px-3">
                          Attach File<span className="text-danger">*</span>
                        </div>
                      </div>
                    ) : null}
                  </Upload>
                </Form.Item>
              </Col>
            </>
          ) : selectedCountry === "Tanzania" ? (
            <>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="wcf"
                  label="WCF Number:"
                  rules={[
                    { required: true, message: "WCF Number is required" },
                  ]}
                >
                  <Input
                    placeholder="Enter No."
                    maxLength={20}
                    onKeyDown={(e) => {
                      const value =
                        /^[0-9]$/?.test(e.key) || /^[a-zA-Z\s]+$/?.test(e.key);
                      if (!value && e.key !== "Backspace" && /^[0-9]$/) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="wcfFile"
                  valuePropName="fileList"
                  getValueFromEvent={(e) =>
                    Array.isArray(e) ? e : e && e.fileList
                  }
                  rules={[
                    {
                      validator: validateFiles,
                    },
                  ]}
                >
                  <Upload
                    accept=".pdf, image/*"
                    onRemove={() => {
                      form.resetFields(["wcfFile"]);
                    }}
                    listType="text"
                    fileList={form.getFieldValue("wcfFile")?.fileList || []}
                    onChange={(file) => handleUploadChange(file, "wcf")}
                    beforeUpload={() => false}
                    maxCount={1}
                  >
                    {!form.getFieldValue("wcfFile")?.length > 0 ? (
                      <div>
                        <div className="w-100 attach_btn px-3">
                          Attach File<span className="text-danger">*</span>
                        </div>
                      </div>
                    ) : null}
                  </Upload>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="nssf"
                  label="NSSF Number:"
                  rules={[
                    { required: true, message: "NSSF Number is required" },
                  ]}
                >
                  <Input
                    placeholder="Enter No."
                    maxLength={20}
                    onKeyDown={(e) => {
                      const value =
                        /^[0-9]$/?.test(e.key) || /^[a-zA-Z\s]+$/?.test(e.key);
                      if (!value && e.key !== "Backspace" && /^[0-9]$/) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="nssfFile"
                  valuePropName="fileList"
                  getValueFromEvent={(e) =>
                    Array.isArray(e) ? e : e && e.fileList
                  }
                  rules={[
                    {
                      validator: validateFiles,
                    },
                  ]}
                >
                  <Upload
                    accept=".pdf, image/*"
                    onRemove={() => {
                      form.resetFields(["nssfFile"]);
                    }}
                    listType="text"
                    fileList={form.getFieldValue("nssfFile")?.fileList || []}
                    onChange={(file) => handleUploadChange(file, "nssf")}
                    beforeUpload={() => false}
                    maxCount={1}
                  >
                    {!form.getFieldValue("nssfFile")?.length > 0 ? (
                      <div>
                        <div className="w-100 attach_btn px-3">
                          Attach File<span className="text-danger">*</span>
                        </div>
                      </div>
                    ) : null}
                  </Upload>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="tin"
                  label="TIN Number:"
                  rules={[
                    { required: true, message: "TIN Number is required" },
                  ]}
                >
                  <Input
                    placeholder="Enter No."
                    maxLength={20}
                    onKeyDown={(e) => {
                      const value =
                        /^[0-9]$/?.test(e.key) || /^[a-zA-Z\s]+$/?.test(e.key);
                      if (!value && e.key !== "Backspace" && /^[0-9]$/) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="tinFile"
                  valuePropName="fileList"
                  getValueFromEvent={(e) =>
                    Array.isArray(e) ? e : e && e.fileList
                  }
                  rules={[
                    {
                      validator: validateFiles,
                    },
                  ]}
                >
                  <Upload
                    accept=".pdf, image/*"
                    onRemove={() => {
                      form.resetFields(["tinFile"]);
                    }}
                    listType="text"
                    fileList={form.getFieldValue("tinFile")?.fileList || []}
                    onChange={(file) => handleUploadChange(file, "tin")}
                    beforeUpload={() => false}
                    maxCount={1}
                  >
                    {!form.getFieldValue("tinFile")?.length > 0 ? (
                      <div>
                        <div className="w-100 attach_btn px-3">
                          Attach File<span className="text-danger">*</span>
                        </div>
                      </div>
                    ) : null}
                  </Upload>
                </Form.Item>
              </Col>
            </>
          ) : selectedCountry === "Rwanda" ? (
            <>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="tin"
                  label="TIN Number:"
                  rules={[
                    { required: true, message: "TIN Number is required" },
                  ]}
                >
                  <Input
                    placeholder="Enter No."
                    maxLength={20}
                    onKeyDown={(e) => {
                      const value =
                        /^[0-9]$/?.test(e.key) || /^[a-zA-Z\s]+$/?.test(e.key);
                      if (!value && e.key !== "Backspace" && /^[0-9]$/) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="tinFile"
                  valuePropName="fileList"
                  getValueFromEvent={(e) =>
                    Array.isArray(e) ? e : e && e.fileList
                  }
                  rules={[
                    {
                      validator: validateFiles,
                    },
                  ]}
                >
                  <Upload
                    accept=".pdf, image/*"
                    onRemove={() => {
                      form.resetFields(["tinFile"]);
                    }}
                    listType="text"
                    fileList={form.getFieldValue("tinFile")?.fileList || []}
                    onChange={(file) => handleUploadChange(file, "tin")}
                    beforeUpload={() => false}
                    maxCount={1}
                  >
                    {!form.getFieldValue("tinFile")?.length > 0 ? (
                      <div>
                        <div className="w-100 attach_btn px-3">
                          Attach File<span className="text-danger">*</span>
                        </div>
                      </div>
                    ) : null}
                  </Upload>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="rssb"
                  label="RSSB Number:"
                  rules={[
                    { required: true, message: "RSSB Number is required" },
                  ]}
                >
                  <Input
                    placeholder="Enter No."
                    maxLength={20}
                    onKeyDown={(e) => {
                      const value =
                        /^[0-9]$/?.test(e.key) || /^[a-zA-Z\s]+$/?.test(e.key);
                      if (!value && e.key !== "Backspace" && /^[0-9]$/) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="rssbFile"
                  valuePropName="fileList"
                  getValueFromEvent={(e) =>
                    Array.isArray(e) ? e : e && e.fileList
                  }
                  rules={[
                    {
                      validator: validateFiles,
                    },
                  ]}
                >
                  <Upload
                    accept=".pdf, image/*"
                    onRemove={() => {
                      form.resetFields(["rssbFile"]);
                    }}
                    listType="text"
                    fileList={form.getFieldValue("rssbFile")?.fileList || []}
                    onChange={(file) => handleUploadChange(file, "rssb")}
                    beforeUpload={() => false}
                    maxCount={1}
                  >
                    {!form.getFieldValue("rssbFile")?.length > 0 ? (
                      <div>
                        <div className="w-100 attach_btn px-3">
                          Attach File<span className="text-danger">*</span>
                        </div>
                      </div>
                    ) : null}
                  </Upload>
                </Form.Item>
              </Col>
            </>
          ) : selectedCountry === "Ethiopia" ? (
            <>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="tin"
                  label="TIN Number:"
                  rules={[
                    { required: true, message: "TIN Number is required" },
                  ]}
                >
                  <Input
                    placeholder="Enter No."
                    maxLength={20}
                    onKeyDown={(e) => {
                      const value =
                        /^[0-9]$/?.test(e.key) || /^[a-zA-Z\s]+$/?.test(e.key);
                      if (!value && e.key !== "Backspace" && /^[0-9]$/) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="tinFile"
                  valuePropName="fileList"
                  getValueFromEvent={(e) =>
                    Array.isArray(e) ? e : e && e.fileList
                  }
                  rules={[
                    {
                      validator: validateFiles,
                    },
                  ]}
                >
                  <Upload
                    accept=".pdf, image/*"
                    onRemove={() => {
                      form.resetFields(["tinFile"]);
                    }}
                    listType="text"
                    fileList={form.getFieldValue("tinFile")?.fileList || []}
                    onChange={(file) => handleUploadChange(file, "tin")}
                    beforeUpload={() => false}
                    maxCount={1}
                  >
                    {!form.getFieldValue("tinFile")?.length > 0 ? (
                      <div>
                        <div className="w-100 attach_btn px-3">
                          Attach File<span className="text-danger">*</span>
                        </div>
                      </div>
                    ) : null}
                  </Upload>
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="pension"
                  label="Pension Number:"
                  // rules={[
                  //   { required: true, message: "Pension Number is required" },
                  // ]}
                >
                  <Input
                    placeholder="Enter No."
                    maxLength={20}
                    onKeyDown={(e) => {
                      const value =
                        /^[0-9]$/?.test(e.key) || /^[a-zA-Z\s]+$/?.test(e.key);
                      if (!value && e.key !== "Backspace" && /^[0-9]$/) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="pensionFile"
                  valuePropName="fileList"
                  getValueFromEvent={(e) =>
                    Array.isArray(e) ? e : e && e.fileList
                  }
                  // rules={[
                  //   {
                  //     validator: validateFiles,
                  //   },
                  // ]}
                >
                  <Upload
                    accept=".pdf, image/*"
                    onRemove={() => {
                      form.resetFields(["pensionFile"]);
                    }}
                    listType="text"
                    fileList={form.getFieldValue("pensionFile")?.fileList || []}
                    onChange={(file) => handleUploadChange(file, "pension")}
                    beforeUpload={() => false}
                    maxCount={1}
                  >
                    {!form.getFieldValue("pensionFile")?.length > 0 ? (
                      <div>
                        <div className="w-100 attach_btn px-3">Attach File</div>
                      </div>
                    ) : null}
                  </Upload>
                </Form.Item>
              </Col>
            </>
          ) : selectedCountry === "Congo" ? (
            <>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="nif"
                  label="National ID Number"
                  rules={[
                    {
                      required: true,
                      message: "National ID Number is required",
                    },
                  ]}
                >
                  <Input
                    placeholder="National ID Number"
                    maxLength={20}
                    onKeyDown={(e) => {
                      const value =
                        /^[0-9]$/?.test(e.key) || /^[a-zA-Z\s]+$/?.test(e.key);
                      if (!value && e.key !== "Backspace" && /^[0-9]$/) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="nifFile"
                  valuePropName="fileList"
                  getValueFromEvent={(e) =>
                    Array.isArray(e) ? e : e && e.fileList
                  }
                  rules={[
                    {
                      validator: validateFiles,
                    },
                  ]}
                >
                  <Upload
                    accept=".pdf, image/*"
                    onRemove={() => {
                      form.resetFields(["nifFile"]);
                    }}
                    listType="text"
                    fileList={form.getFieldValue("nifFile")?.fileList || []}
                    onChange={(file) => handleUploadChange(file, "nif")}
                    beforeUpload={() => false}
                    maxCount={1}
                  >
                    {!form.getFieldValue("nifFile")?.length > 0 ? (
                      <div>
                        <div className="w-100 attach_btn px-3">
                          Attach File<span className="text-danger">*</span>
                        </div>
                      </div>
                    ) : null}
                  </Upload>
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="inss"
                  label="CNSS Number"
                  rules={[
                    { required: true, message: "CNSS Number is required" },
                  ]}
                >
                  <Input
                    placeholder="CNSS Number"
                    maxLength={20}
                    onKeyDown={(e) => {
                      const value =
                        /^[0-9]$/?.test(e.key) || /^[a-zA-Z\s]+$/?.test(e.key);
                      if (!value && e.key !== "Backspace" && /^[0-9]$/) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  // label=""
                  name="inssFile"
                  valuePropName="fileList"
                  getValueFromEvent={(e) =>
                    Array.isArray(e) ? e : e && e.fileList
                  }
                  rules={[
                    {
                      validator: validateFiles,
                    },
                  ]}
                >
                  <Upload
                    accept=".pdf, image/*"
                    listType="text"
                    onChange={(file) => handleUploadChange(file, "inss")}
                    onRemove={() => {
                      form.resetFields(["inssFile"]);
                    }}
                    beforeUpload={() => false}
                    maxCount={1}
                  >
                    {!form.getFieldValue("inssFile")?.length > 0 ? (
                      <div>
                        <div className="w-100 attach_btn px-3">
                          Attach File<span className="text-danger">*</span>
                        </div>
                      </div>
                    ) : null}
                  </Upload>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="nhi"
                  label="NIF Tax Number"
                  rules={[
                    { required: true, message: "NIF Number is required" },
                  ]}
                >
                  <Input
                    placeholder="NIF Number"
                    maxLength={20}
                    onKeyDown={(e) => {
                      const value =
                        /^[0-9]$/?.test(e.key) || /^[a-zA-Z\s]+$/?.test(e.key);
                      if (!value && e.key !== "Backspace" && /^[0-9]$/) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="nhiFile"
                  valuePropName="fileList"
                  getValueFromEvent={(e) =>
                    Array.isArray(e) ? e : e && e.fileList
                  }
                  rules={[
                    {
                      validator: validateFiles,
                    },
                  ]}
                  // rules={[
                  //   {
                  //     validator: (_, value) =>
                  //       fileList?.length > 0
                  //         ? Promise.resolve()
                  //         : Promise.reject(new Error("Please upload an image")),
                  //   },
                  // ]}
                >
                  <Upload
                    accept=".pdf, image/*"
                    listType="text"
                    // name={["name", "attachment"]}
                    onChange={(file) => handleUploadChange(file, "nhi")}
                    onRemove={() => {
                      form.resetFields(["nhiFile"]);
                    }}
                    beforeUpload={() => false}
                    maxCount={1}
                  >
                    {!form.getFieldValue("nhiFile")?.length > 0 ? (
                      <div>
                        <div className="w-100 attach_btn px-3">
                          Attach File<span className="text-danger">*</span>
                        </div>
                      </div>
                    ) : null}
                  </Upload>
                </Form.Item>
              </Col>
            </>
          ) : (
            <>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="nif"
                  label="NIF"
                  rules={[{ required: true, message: "NIF is required" }]}
                >
                  <Input
                    placeholder="NIF"
                    maxLength={20}
                    onKeyDown={(e) => {
                      const value =
                        /^[0-9]$/?.test(e.key) || /^[a-zA-Z\s]+$/?.test(e.key);
                      if (!value && e.key !== "Backspace" && /^[0-9]$/) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="nifFile"
                  valuePropName="fileList"
                  getValueFromEvent={(e) =>
                    Array.isArray(e) ? e : e && e.fileList
                  }
                  rules={[
                    {
                      validator: validateFiles,
                    },
                  ]}
                >
                  <Upload
                    accept=".pdf, image/*"
                    onRemove={() => {
                      form.resetFields(["nifFile"]);
                    }}
                    listType="text"
                    fileList={form.getFieldValue("nifFile")?.fileList || []}
                    onChange={(file) => handleUploadChange(file, "nif")}
                    beforeUpload={() => false}
                    maxCount={1}
                  >
                    {!form.getFieldValue("nifFile")?.length > 0 ? (
                      <div>
                        <div className="w-100 attach_btn px-3">
                          Attach File<span className="text-danger">*</span>
                        </div>
                      </div>
                    ) : null}
                  </Upload>
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="inss"
                  label="INSS"
                  rules={[
                    { required: true, message: "INSS Number is required" },
                  ]}
                >
                  <Input
                    placeholder="INSS Number"
                    maxLength={20}
                    onKeyDown={(e) => {
                      const value =
                        /^[0-9]$/?.test(e.key) || /^[a-zA-Z\s]+$/?.test(e.key);
                      if (!value && e.key !== "Backspace" && /^[0-9]$/) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  // label=""
                  name="inssFile"
                  valuePropName="fileList"
                  getValueFromEvent={(e) =>
                    Array.isArray(e) ? e : e && e.fileList
                  }
                  rules={[
                    {
                      validator: validateFiles,
                    },
                  ]}
                >
                  <Upload
                    accept=".pdf, image/*"
                    listType="text"
                    onChange={(file) => handleUploadChange(file, "inss")}
                    onRemove={() => {
                      form.resetFields(["inssFile"]);
                    }}
                    beforeUpload={() => false}
                    maxCount={1}
                  >
                    {!form.getFieldValue("inssFile")?.length > 0 ? (
                      <div>
                        <div className="w-100 attach_btn px-3">
                          Attach File<span className="text-danger">*</span>
                        </div>
                      </div>
                    ) : null}
                  </Upload>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="nhi"
                  label="NHI Number"
                  rules={[
                    { required: true, message: "NHI Number is required" },
                  ]}
                >
                  <Input
                    placeholder="NHI Number"
                    maxLength={20}
                    onKeyDown={(e) => {
                      const value =
                        /^[0-9]$/?.test(e.key) || /^[a-zA-Z\s]+$/?.test(e.key);
                      if (!value && e.key !== "Backspace" && /^[0-9]$/) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="nhiFile"
                  valuePropName="fileList"
                  getValueFromEvent={(e) =>
                    Array.isArray(e) ? e : e && e.fileList
                  }
                  rules={[
                    {
                      validator: validateFiles,
                    },
                  ]}
                  // rules={[
                  //   {
                  //     validator: (_, value) =>
                  //       fileList?.length > 0
                  //         ? Promise.resolve()
                  //         : Promise.reject(new Error("Please upload an image")),
                  //   },
                  // ]}
                >
                  <Upload
                    accept=".pdf, image/*"
                    listType="text"
                    // name={["name", "attachment"]}
                    onChange={(file) => handleUploadChange(file, "nhi")}
                    onRemove={() => {
                      form.resetFields(["nhiFile"]);
                    }}
                    beforeUpload={() => false}
                    maxCount={1}
                  >
                    {!form.getFieldValue("nhiFile")?.length > 0 ? (
                      <div>
                        <div className="w-100 attach_btn px-3">
                          Attach File<span className="text-danger">*</span>
                        </div>
                      </div>
                    ) : null}
                  </Upload>
                </Form.Item>
              </Col>
            </>
          )}
          <Col xs={24} sm={24} md={24}>
            <Form.Item
              label="Upload CV"
              name="cv"
              className="cv_file"
              valuePropName="fileList"
              getValueFromEvent={(e) =>
                Array.isArray(e) ? e : e && e.fileList
              }
              rules={[
                {
                  validator: validateFiles,
                },
              ]}
            >
              <Upload
                accept=".pdf, image/*"
                listType="text"
                // name={["name", "attachment"]}
                onChange={(file) => handleUploadChange(file, "CV")}
                onRemove={() => {
                  form.resetFields(["cv"]);
                }}
                beforeUpload={() => false}
                maxCount={1}
              >
                {!form.getFieldValue("cv")?.length > 0 ? (
                  <div>
                    <div className="w-100 attach_btn_cv px-3">Attach File</div>
                  </div>
                ) : null}
              </Upload>
            </Form.Item>
          </Col>
        </StyleRow>
        <div className="text-end"></div>
      </>
    </div>
  );
}

export default WorkDetails;
